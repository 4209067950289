$pink: #e21ca4;
$green: #d1ff6a;
$black: #191414;
$seagreen: #48937e;
$body-font: 'Anonymous Pro', 'Courier New';

@font-face {
  font-family: Circular Spotify;
  src: url('assets/CircularSpotifyTxT-Black.ttf');
}

@font-face {
  font-family: Circular Book;
  src: url('assets/Circular Book.ttf');
}

@font-face {
  font-family: Anonymous Pro;
  src: url('assets/AnonymousPro-Regular.ttf');
}

@font-face {
  font-family: Editorial New;
  src: url('assets/PPEditorialNew-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: Editorial New;
  src: url('assets/PPEditorialNew-Ultrabold.otf');
  font-weight: bold;
}

html {
  overflow: auto;
  height: 100%;
}

body {
  font-family: $body-font, monospace;
  font-size: 16px;
  line-height: 1.5;
  height: auto;
}

h1,
h2,
h3,
h4 {
  margin: 0.3em 0;
}

h1 {
  font-size: 40px;
  margin: 0.5em 0;
  font-family: Editorial New, 'Helvetica', sans-serif;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

h2,
h3 {
  letter-spacing: -.02em;
  font-weight: 100;
  font-family: Editorial New, 'Helvetica', sans-serif;
}

p {
  margin: 0;
}

div {
  box-sizing: border-box;
}

a {
  color: $pink;
}

button {
  margin: 15px 0;
  background: white;
  border: 1px solid black;
  padding: 1em 2em;
  text-transform: uppercase;
  font-weight: 800;
  color: $black;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.5s;
  font-family: $body-font, monospace;
  box-shadow: 5px 5px 0 0 black;
  font-size: 1.2em;
  border-radius: 0.375rem;

  a {
    color: $black;
    text-decoration: none;
  }

  &:hover {
    background: black;
    color: white;
  }
}

input[type="text"] {
  font-family: $body-font, monospace;
  border-radius: 0;
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px;
}

.App {
  // text-align:center;
}

.start-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  height: calc(100vh - 80px);
}

.title-wrapper {
  width: 100%;
  max-width: 500px;
  border-radius: 50%;
  border: 1px dashed black;
  padding: 80px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  background: white;
  box-shadow: 1px 1px 0 black,
    2px 2px 0 black,
    3px 3px 0 black,
    4px 4px 0 black,
    5px 5px 0 black,
    6px 6px 0 black,
    7px 7px 0 black,
    8px 8px 0 black,
    9px 9px 0 black,
    10px 10px 0 black;

  h1 {
    margin: 10px 0;
    font-family: Editorial New, 'Helvetica', sans-serif;
    text-decoration: none;
    font-size: 70px;
    line-height: 80px;
  }

  p {
    font-family: Editorial New, 'Helvetica', sans-serif;
    font-size: 24px;
  }

  .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    background: black;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: -1;
  }
}

.credit {
  position: absolute;
  bottom: 10px;
  font-family: Editorial New, serif;
}

.container {
  display: flex;
}

.sidebar {
  width: 50%;
  border-right: 1px dashed black;
  padding: 50px;
  height: 100vh;
  overflow-y: scroll;
  position: relative;

  .logo {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 18px;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  // justify-content: center;
}

.selections {
  margin: 10px 0px;
  width: 100%;

  &.checkbox {
    display: flex;
    align-items: center;
    border: 1px solid black;
    padding: 10px 15px;
    border-radius: 0.375rem;
    margin-top: 20px;
    box-shadow: 5px 5px 0 0 black;

    h3 {
      font-size: 18px;
      margin-bottom: 2px;
    }

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
    }
  }

  input[type="text"] {
    width: 100%;
  }

  h2 {
    font-family: $body-font, monospace;
  }
}

.top-artist {
  padding: 15px;
  border: 1px solid black;
  border-radius: 0.375rem;
  margin-bottom: 10px;
  box-shadow: 5px 5px 0 0 black;
  width: 100%;
  display: flex;
  position: relative;
}

.entity-wrapper {
  width: 100%;
  display: flex;
  padding: 5px 0;
  //border: 1px solid red;

  .change-link {
    color: $pink;
    cursor: pointer;
    margin-left: 10px;
  }

  .entity-number {
    width: 20px;
  }
}

.search {
  position: relative;
  width: 100%;

  input[type="text"] {
    width: 100%;
    font-family: $body-font, monospace;
    font-size: 16px;
    box-sizing: border-box;
    border: none;
  }

  button {
    font-family: $body-font, monospace;
    font-size: 12px;
    padding: 0;
    background: transparent;
    margin: 0 10px;
    border: none;
    box-shadow: none;

    img {
      width: 20px;
    }
  }

  .search-form {
    z-index: 1;
    display: flex;
    align-items: center;
  }

  .search-bar-wrapper {
    width: 100%;
    position: relative;
  }
}

.results {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid black;
  z-index: 5;
  background: white;
  top: 56px;
  left: 0;

  p {
    margin: 5px;
  }
}

.results-item {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid black;
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  text-align: left;

  &:hover {
    background: black;
    color: white;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  h3 {
    font-size: 1em;
    font-weight: 100;
    margin: 0;
    font-family: $body-font, monospace;
  }

  h4 {
    font-size: 0.8em;
    font-weight: 100;
    margin: 0;
    font-family: $body-font, monospace;
  }
}

@mixin line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 5s linear;
  animation: colorful 15s infinite;
  background: $black;
  z-index: 20;
  text-align: center;

  button {
    background: white;

    &:hover {
      background: black
    }
  }
}

.canvas-credit {
  display: none;
}

.scrollable {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-graphic-wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  max-height: 100vh;
  justify-content: center;

  .graphics-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .canvas-title {
    width: 400px;
    padding: 1em;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1em;
  }

  canvas {
    position: static;
    width: 300px;
    height: 533px;
    margin: 0 10px;
  }

  h1 {
    color: $black;
    line-height: 1.2em;
  }

  h3 {
    color: $green;
  }

  button {
    font-size: 14px;
    margin: 5px 0;
  }
}

.invisible {
  display: none;
}

.color-selector {
  display: flex;
  margin: 20px 0;
  flex-grow: 0;
  flex-shrink: 0;

  .color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 3px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;

    &.selected {
      border: 1px solid black;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px dashed black;
    height: auto;

    .logo {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .canvas-graphic-wrapper {
    width: 100%;
    max-height: none;
  }

  .start-container {
    padding: 50px 20px;
  }

  .title-wrapper {
    padding: 20%;

    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    p {
      font-size: 20px;
    }

    button {
      font-size: 14px;
    }
  }
}